<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item >首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>签约球馆</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <el-table :data="list" stripe border><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="a" label="球馆名称"></el-table-column>
                <el-table-column prop="a" label="门店类型"></el-table-column>
                <el-table-column prop="a" label="负责人"></el-table-column>
                <el-table-column prop="a" label="联系方式"></el-table-column>
                <el-table-column prop="a" label="门店地址"></el-table-column>
                <el-table-column prop="a" label="营业时间"></el-table-column>
                <el-table-column prop="a" label="门店特色"></el-table-column>
                <el-table-column prop="a" label="营业执照"></el-table-column>
                <el-table-column prop="a" label="封面图"></el-table-column>
                <el-table-column prop="a" label="球馆图"></el-table-column>
                <el-table-column prop="a" label="门店介绍"></el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
export default {
name: "signArena",
    data() {
        return {
            list: [],
        }
    },
    mounted () {
        //this.getList()
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
